@import url("//hello.myfonts.net/count/324876");
@font-face {
  font-family: "TradeGothicLTPro-BdCn20";
  src: url("./fonts/324876_0_0.eot");
  src: url("./fonts/324876_0_0.eot?#iefix") format("embedded-opentype");
  src: url("./fonts/324876_0_0.woff2") format("woff2");
  src: url("./fonts/324876_0_0.woff") format("woff");
  src: url("./fonts/324876_0_0.ttf") format("truetype");
}
body {
  max-width: 100%;
}
body.signed-out #image-gallery {
  display: none;
}
body.signed-out #user-info {
  display: none;
}
body.signed-out .header {
  margin-top: 20%;
  flex-direction: column;
}
body.signed-out .header .logo {
  margin: 0 auto 20px;
}
body.signed-out #google-sign-in {
  display: inline-block;
}
body.loading #user-info {
  visibility: hidden;
}
body.loading #image-gallery {
  display: none;
}

#google-sign-in {
  display: none;
}

p {
  margin-bottom: 10px
}
button {
  padding: 5px 12px;
  outline: none;
  border: none;
  background-color: #f4f4f4;
  font-size: 12px;
  border-radius: 4px;
}
button:hover {
  background-color: #eee;
}

.text-center {
  text-align: center;
}

.fetching-area {
  margin-top: 25px;
  margin-bottom: 50px;
  padding-bottom: 50px;
}
.fetching-area.false svg {
  display: none;
}

.header {
  height: 60px;
  width: 100%;
  margin: 15px 0;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 300px;
  max-width: 100%;
}

.coverscreen {
  height: 100vh;
  width: 100vw;
  background-color: #14082E;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
}
.coverscreen.loading-false {
  animation: 1s fade-out forwards;
}

@keyframes fade-out{
  0%    {opacity: 1; }
  100%  {opacity: 0;}
}


#searchInput {
  width: 100%;
  box-sizing: border-box;
  font-size: 18px;
  height: 44px;
  opacity: 1;
  text-transform: uppercase;
  padding-right: 30px;
  padding-left: 15px;
  font-weight: 400;
  font-family: "TradeGothicLTPro-BdCn20", sans-serif;
  margin-bottom: 5px;
  color: #371C16;
  box-shadow: none!important;
  border-radius: 5px;
  border: 1px solid #e6e5ea;
}

.brand-logo {
  max-width: 70px;
  max-height: 50px;
  opacity: 0.4;
  padding: 5px;
  font-size: 8px;
  cursor: pointer;
}
.brand-logo:hover {
  opacity: 0.7;
}
.brand-logo.active {
  opacity: 1;
}

@media (min-width: 992px) {
  .brand-logo {
    max-width: 80px;
    max-height: 70px;
  }
  .logo {
    width: 400px;
  }
}

.row-breaker {
  display: table-row;
}

.image-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.image-list .image-list__item {
  flex: 1 1 auto;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin: 3px;
  max-height: 280px;
  min-width: 175px;
  text-align: center;
  transition: opacity 0.4s;
}
.image-list .image-list__item._m {
  flex: 1 2 150px;
}
.image-list .image-list__item._n {
  flex: 2 1 auto;
}
.image-list .image-list__item._c {
  flex: 4 1 auto;
  padding-bottom: 10px;
}
@media (min-width: 600px) {
  .image-list .image-list__item {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .image-list .image-list__item {
    /* max-width: 60%; */
  }
}
.image-list .image-list__item:hover p.image-list__item-date {
  height: 15px;
}
.image-list .image-list__item.unloaded {
  opacity: 1;
}
.image-list .image-list__item.loaded {
  opacity: 1;
}
.image-list .image-list__item img {
  max-width: 100%;
  min-height: 75px;
  max-height: 280px;
  min-width: 75px;
  background-color: #f9f9f9
}
.image-list.view-grid .image-list__item img {
  /*min-width: 175px;*/
  min-height: 50px;
}
.image-list .gif img {
  width: 300px;
}
.image-list .pdf img {
  padding: 30px;
  width: 200px;
}
.image-list .image-list__item-text {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.image-list .image-list__item-text p {
  margin: 0;
  font-size: 10px;
  color: #000;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.7);
}
.image-list .image-list__item-text .image-list__item-date {
  height: 0;
  transition: height 0.4s;
}
.image-list .img-hidden {
  opacity: 0;
}


.image-list.view-list .image-list__item {
  width: 100%;
  max-width: none !important;
  margin: 5px 0;
  display: flex;
  flex: 1 1 auto;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}
@media (min-width: 768px) {
  .image-list.view-list .image-list__item {
    width: 50%;
  }
}
.image-list.view-list img {
  width: 75px;
  height: 75px;
}
.image-list.view-list .image-list__item-text {
  position: static;
  text-align: left;
}
.image-list.view-list .image-list__item-text p {
  font-size: 12px;
  margin-left: 5px;
}


.view-icon {
  display: inline-block;
  width: 35px;
  opacity: .3;
  cursor: pointer;
  margin: 1px 11px;
}
.view-icon:hover {
  opacity: .7;
}
.view-icon.active {
  cursor: auto;
  opacity: 1;
}
.view-icon img {
  max-width: 100%;
  background: white;
}

.text-filter {
  display: inline-block;
  font-family: "TradeGothicLTPro-BdCn20", sans-serif;
  margin-left: 15px;
  vertical-align: middle;
  cursor: pointer;
  opacity: .4;
}
.text-filter:hover {
  opacity: .8;
}
.text-filter.active {
  opacity: 1;
}
.text-filter * {
  line-height: 1;
}

.text-link {
  cursor: pointer;
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal.show {
  display: block;
  opacity: 1;
}
.rs-modal-full {
  width: 100%;
}
.modal.show .modal-fs, .rs-modal-dialog {
  max-width: 95%;
  max-height: 90%;
}
.modal.show .modal-body, .rs-modal-body {
  text-align: center;
  padding: 0;
  max-height: fit-content !important;
}
.modal.show .modal-header, .modal.show .modal-footer {
  border: none;
}
.modal.show .modal-mainimage, .rs-modal .modal-mainimage {
  max-width: calc(100% - 4rem);
  max-height: calc(100vh - 13rem);
}
.modal-nav {
  width: 30px;
  cursor: pointer;
}
.modal-nav.left-arrow {
  left: 1rem;
}
.modal-nav.right-arrow {
  right: 1rem;
}
.modal-download {
  width: 40px;
  cursor: pointer;
}
.modal-download:disabled {
  cursor: wait;
}

.modal-title svg {
  max-width: 20px;
  margin-top: -5px;
  cursor: pointer;
}


.rs-list-item-content {
  display: grid;
  height: 50px;
}

.rs-table-row-header .rs-table-cell {
  background: #14082e;
  color: #ffffff;
}

.brandList-logo {
  max-width: 80px;
  max-height: 50px;
}

.brandList-name[contenteditable="true"] {
  border: blue;
  background: white;
}
